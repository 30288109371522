<template>
  <div class="container">
    <table
      class="table"
      width="1000"
      border="0"
      cellspacing="30"
      cellpadding="0"
    >
      <tbody>
        <tr>
          <td>
            <div align="center">
              <font size="+2"></font><br />
              <br />
              <table
                width="700"
                align="center"
                border="0"
                cellspacing="5"
                cellpadding="0"
              >
                <tbody>
                  <tr>
                    <td>
                      <a href="http://www.cs.columbia.edu/%7Ejwgu">Jinwei Gu</a
                      >&nbsp;&nbsp;&nbsp;&nbsp;
                    </td>
                    <td>&nbsp;Chien-i Tu*</td>
                    <td>Ravi Ramamoorthi</td>
                  </tr>
                  <tr>
                    <td>
                      <a href="http://www.cs.columbia.edu/%7Ebelhumeur"
                        >Peter Belhumeur</a
                      >
                    </td>
                    <td>
                      <a href="http://people.csail.mit.edu/wojciech"
                        >Wojciech Matusik</a
                      >*
                    </td>
                    <td>
                      <a href="http://www.cs.columbia.edu/%7Enayar"
                        >Shree Nayar</a
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <div align="center">
                        Columbia Univeristy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; MERL*
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <img
            class="img"
            src="https://cave.cs.columbia.edu/old/databases/staf/images/main.png"
            width="1000"
            margin="auto"
            height="400"
          />
        </tr>
        <tr>
          <td>
            <div align="center">
              <table width="100%" border="0" cellspacing="5" cellpadding="0">
                <tbody>
                  <tr>
                    <td>
                      <div align="center">
                        <router-link to="/repository/STAF/about"
                          >About the Database</router-link
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div align="center">
                        <router-link to="/repository/STAF/db"
                          >Database Organization and Format</router-link
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div align="center">
                        <router-link to="/repository/STAF/documentation"
                          >Documentation</router-link
                        >
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div align="center">
                        <router-link to="/repository/STAF/samples"
                          >List of Samples and Code Download</router-link
                        >
                      </div>
                    </td>
                  </tr>
                  <!-- <tr>
                  <td><div align="center"><a href="http://cave.cs.columbia.edu/old/projects/time_var/time_var.php">Project  Page</a></div></td>
                </tr> -->
                </tbody>
              </table>
              <br />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <hr />
            <br />
            Contact:&nbsp;<a href="mailto:%20staf@lists.cs.columbia.edu"
              >staf@lists.cs.columbia.edu</a
            ><br />
            Last modified: 08/28/2006
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {};
</script>

<style>
.container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  font-size: 18px;
}
.table {
  margin: auto;
}
.img {
  margin: auto;
}
</style>